import React, { ReactElement, useEffect } from 'react';
import { Banner } from '@airbus/components-react';

type Extend = {
  children: ReactElement | string;
  severity: 'success' | 'warning' | 'error';
  stopAutoClose?: boolean;
};
export type AlertBarProp = Extend | null;
export const AlertBar = (props) => {
  const { alertbar, setAlertbar } = props;
  useEffect(() => {
    if (alertbar && !alertbar?.stopAutoClose) {
      setTimeout(() => {
        setAlertbar(null);
      }, 6000);
    }
    const alertBar = document.getElementById('alertBarBanner');
    if (alertBar) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [alertbar]);

  return alertbar ? (
    <Banner
      variant={alertbar?.severity}
      style={{ marginBottom: 2 }}
      onClose={() => setAlertbar(null)}
      id='alertBarBanner'
    >
      {alertbar?.children}
    </Banner>
  ) : (
    <></>
  );
};
