import { fetchGraphQL } from './fetchApi';

function fetchMyQuery(operationsDoc, operationName) {
  return fetchGraphQL(operationsDoc, operationName, {});
}

async function startFetchMyQuery(operationQuery, operationName) {
  const { errors, data } = await fetchMyQuery(operationQuery, operationName);

  if (errors) {
    return { errors };
  }

  // do something great with this precious data
  return data;
}

export const getData = (operationQuery, operationName = 'MyQuery') =>
  startFetchMyQuery(operationQuery, operationName);
