import { ReactElement, useEffect, useState } from 'react';

import { Typography } from '@airbus/components-react';

import styles from './index.module.css';

import { useLanguageContextAPI } from '../shared/context/LanguageContext';
import { useUserContextApi } from '../shared/context/UserContext';
import { CONFIG_SCHEMA } from '../shared/services/config';
import { getData } from '../shared/services/getDataService';

import Layout from '../shared/components/layout';
import Spinners from '../shared/components/Spinner';
import ScreenTabs from '../features/landing_page/ScreenTabs';
import DisplayScreens from '../features/landing_page/DisplayScreens';

import { AccessData, ScreenData, UserData, UserDetailsData } from '../shared/types';
import { AlertBar } from '../shared/components/AlertBar';

export default function Home() {
  const {
    loginDetails,
    accessControlDetails,
    userDetails,
    updateUserDetails,
  }: {
    loginDetails: UserData;
    accessControlDetails: AccessData;
    userDetails: UserDetailsData;
    updateUserDetails: React.Dispatch<UserDetailsData>;
  } = useUserContextApi();
  const { t } = useLanguageContextAPI();
  //   Screens State
  const [noAccessControlDetails, setNoAccessControlDetails] = useState<AccessData>([]);
  const [allScreensDetails, setAllScreensDetails] = useState<ScreenData[]>([]);
  //   Tab State
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [poAlertbar, setPoAlertBar] = useState<{
    children: ReactElement | string;
    severity: 'success' | 'warning' | 'error';
    stopAutoClose?: boolean;
  }>(null);

  useEffect(() => {
    async function fetchAllScreens() {
      const operationsDoc = `
            query GetAllScreenDetails {
                ${CONFIG_SCHEMA}_mbd_screens(distinct_on: screen_internal_name, order_by: {screen_internal_name: asc}) {
                    app_de_display_name
                    app_en_display_name
                    screen_de_display_name
                    screen_en_display_name
                    screen_internal_name
                }
            }
        `;
      try {
        const response: Record<`${typeof CONFIG_SCHEMA}_mbd_screen`, ScreenData[]> = await getData(
          operationsDoc,
          'GetAllScreenDetails',
        );
        setAllScreensDetails(response[`${CONFIG_SCHEMA}_mbd_screens`]);
        setLoading(false);
      } catch (error) {
        setPoAlertBar({ children: t('error_fetching'), severity: 'error' });
      }
    }
    fetchAllScreens();
  }, []);

  useEffect(() => {
    const noAccessControlDetails: AccessData = allScreensDetails
      ?.filter((screen) => {
        const findScreen = accessControlDetails.find(
          (accessScreen) =>
            accessScreen.mbd_screen_to_datagrid.mbd_screen.screen_internal_name ===
            screen.screen_internal_name,
        );

        if (!findScreen) return true;
      })
      .map((screen) => {
        return {
          access_level: 'screen',
          access_type: 'H',

          mbd_screen_to_datagrid: {
            mbd_screen: {
              ...screen,
            },
          },
          screen_grid_id: 0,
        };
      });
    setNoAccessControlDetails(noAccessControlDetails);
  }, [accessControlDetails, allScreensDetails]);

  useEffect(() => {
    updateUserDetails({ ...userDetails, app_details: null });
  }, []);

  return (
    <Layout>
      <AlertBar alertbar={poAlertbar} setAlertbar={setPoAlertBar} />
      {loginDetails.userId ? (
        <>
          <Typography variant='h5'>{t('welcome_header')}</Typography>
          <div className={styles.gridBox}>
            <ScreenTabs {...{ selectedTab, setSelectedTab, open, setOpen }} />
            {loading ? (
              <Spinners />
            ) : accessControlDetails.length > 0 ? (
              <DisplayScreens
                {...{ selectedTab, accessControlDetails, noAccessControlDetails, setPoAlertBar }}
              />
            ) : (
              <Typography variant='h6'>{t('landing_page.no_access')}</Typography>
            )}
          </div>
        </>
      ) : (
        <Typography variant='h3'>{t('not_logged_in')}</Typography>
      )}
    </Layout>
  );
}
