'use client';

import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  'dcb1d6a6f0b41b349b876781cf36670dTz04MTMyOCxFPTE3MzU1NDg1NjQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

export default function MuiXLicense() {
  return null;
}
