import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { Box } from '@mui/material';
import { Card, CardContent, CardHeader, Inline, Typography } from '@airbus/components-react';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import styles from './DisplayScreens.module.css';

import { AccessData, ScreenData } from '../../shared/types';
import { useLanguageContextAPI } from '../../shared/context/LanguageContext';
import { CONFIG_SCHEMA } from '../../shared/services/config';
import { getData } from '../../shared/services/getDataService';
import { AlertBarProp } from '../../shared/components/AlertBar';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface DisplayScreenProps {
  selectedTab: number;
  accessControlDetails: AccessData;
  noAccessControlDetails: AccessData;
  setPoAlertBar: Dispatch<SetStateAction<AlertBarProp>>;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const sortScreensFn = (screenA: AccessData[number], screenB: AccessData[number]) => {
  const textA = screenA.mbd_screen_to_datagrid.mbd_screen.screen_en_display_name.toUpperCase();
  const textB = screenB.mbd_screen_to_datagrid.mbd_screen.screen_en_display_name.toUpperCase();
  return textA > textB === true ? 1 : -1;
};

const DisplayScreens = (props: DisplayScreenProps) => {
  const [businessApps, setBusinessApps] = useState([
    'Finance Repository',
    'NPI-Board',
    'NPI Board',
    'NPI Steering',
    'PO Management',
  ]);
  const [adminApps] = useState(['NPI Tool Administation']);

  const { selectedTab, accessControlDetails, noAccessControlDetails, setPoAlertBar } = props;
  const { t, language } = useLanguageContextAPI();
  const { push } = useRouter();

  const businessAccessScreens = accessControlDetails
    ?.filter((screenData) =>
      businessApps.includes(screenData.mbd_screen_to_datagrid.mbd_screen.app_en_display_name),
    )
    .sort(sortScreensFn)
    ?.map((screenData) => {
      return (
        <>
          <Card
            className={styles.card}
            onClick={() =>
              push(`mbdcp/${screenData.mbd_screen_to_datagrid.mbd_screen.screen_internal_name}`)
            }
          >
            <CardHeader
              icon={<AirplanemodeActiveIcon />}
              title={
                screenData.mbd_screen_to_datagrid.mbd_screen[`screen_${language}_display_name`]
              }
            />
          </Card>
        </>
      );
    });

  const adminAccessScreens = accessControlDetails
    ?.filter((screenData) =>
      adminApps.includes(screenData.mbd_screen_to_datagrid.mbd_screen.app_en_display_name),
    )
    ?.sort(sortScreensFn)
    ?.map((screenData) => {
      return (
        <>
          <Card
            className={styles.card}
            onClick={() =>
              push(`admin/${screenData.mbd_screen_to_datagrid.mbd_screen.screen_internal_name}`)
            }
          >
            <CardHeader
              icon={<AirplanemodeActiveIcon />}
              title={
                screenData.mbd_screen_to_datagrid.mbd_screen[`screen_${language}_display_name`]
              }
            />
            <CardContent></CardContent>
          </Card>
        </>
      );
    });

  const businessNoAccessScreens = noAccessControlDetails
    ?.filter((screenData) =>
      businessApps.includes(screenData.mbd_screen_to_datagrid.mbd_screen.app_en_display_name),
    )
    ?.sort(sortScreensFn)
    ?.map((screenData) => {
      return (
        <>
          <Card className={styles.dimCard}>
            <CardHeader
              icon={<AirplanemodeActiveIcon />}
              title={
                screenData.mbd_screen_to_datagrid.mbd_screen[`screen_${language}_display_name`]
              }
            />
            <CardContent></CardContent>
          </Card>
        </>
      );
    });

  const adminNoAccessScreens = noAccessControlDetails
    ?.filter((screenData) =>
      adminApps.includes(screenData.mbd_screen_to_datagrid.mbd_screen.app_en_display_name),
    )
    ?.sort(sortScreensFn)
    ?.map((screenData) => {
      return (
        <>
          <Card className={styles.dimCard}>
            <CardHeader
              icon={<AirplanemodeActiveIcon />}
              title={
                screenData.mbd_screen_to_datagrid.mbd_screen[`screen_${language}_display_name`]
              }
            />
            <CardContent></CardContent>
          </Card>
        </>
      );
    });

  useEffect(() => {
    async function fetchAllAppDisplayNames() {
      const operationsDoc = `
        query GetAllAppDisplayNames {
                ${CONFIG_SCHEMA}_mbd_screens(distinct_on: app_en_display_name) {
                app_en_display_name
            }
        }
        `;
      try {
        const response: Record<`${typeof CONFIG_SCHEMA}_mbd_screen`, ScreenData[]> = await getData(
          operationsDoc,
          'GetAllAppDisplayNames',
        );
        const screens: ScreenData[] = response[`${CONFIG_SCHEMA}_mbd_screens`];
        if (screens) {
          setBusinessApps(
            screens.map((screen) => {
              if (!adminApps.includes(screen.app_en_display_name))
                return screen.app_en_display_name;
            }),
          );
        }
      } catch (error) {
        setPoAlertBar({ children: t('error_fetching'), severity: 'error' });
      }
    }

    fetchAllAppDisplayNames();
  }, []);

  return (
    <div>
      {/* Business Screens */}
      <CustomTabPanel value={selectedTab} index={0}>
        <Typography variant='h6'>{`Business Screens`}</Typography>
        <Inline wrap spacing='2-x'>
          {businessAccessScreens}
          {businessNoAccessScreens}
        </Inline>
      </CustomTabPanel>
      {/* Admin Screens */}
      <CustomTabPanel value={selectedTab} index={0}>
        <Typography variant='h6'>{`Admin Screens`}</Typography>
        <Inline wrap spacing='2-x'>
          {adminAccessScreens}
          {adminNoAccessScreens}
        </Inline>
      </CustomTabPanel>
    </div>
  );
};

export default DisplayScreens;
