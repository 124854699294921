import { Button } from '@airbus/components-react';
import { useConfigContext } from '../../context/ConfigContext';
import { t } from '../../../i18next';

export default function LoginButton() {
  const config = useConfigContext();

  if (!config.cognitoDomain) {
    return null;
  }

  const callbackUri = encodeURI(config.redirectUri);

  const loginUrl = `${config.cognitoDomain}/oauth2/authorize?client_id=${config.clientId}&redirect_uri=${callbackUri}&response_type=code&scope=openid`;

  const onLogin = () => {
    window.location.replace(loginUrl);
  };

  return (
    <Button variant='ghost' onClick={onLogin}>
      {t('login')}
    </Button>
  );
}
