import { useContext, useState } from 'react';
import { SideNav, SideNavCategory, SideNavItem, SideNavSection } from '@airbus/components-react';
import { useUserContextApi } from '../../context/UserContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useRouter } from 'next/navigation';
import styles from './SideNavigation.module.css';

function SideNavigation(props) {
  const router = useRouter();
  const { language } = useContext(LanguageContext);
  const { accessControlDetails, userDetails } = useUserContextApi();
  const { showSideNav, setShowSideNav } = props;
  const [category, setCategory] = useState(userDetails?.app_details?.app_en_display_name);
  const navigateTo = (appName: string, screenName: number) => {
    const route = appName === 'NPI Tool Administration' ? `/admin` : `/mbdcp`;
    router.push(`${route}/${screenName}`);
  };
  let menuGroup = [];
  if (accessControlDetails.length > 0) {
    const menu = accessControlDetails.reduce((arr, item) => {
      const screenId = item.screen_grid_id;
      const { mbd_screen: screenName } = item.mbd_screen_to_datagrid;
      const screenNameId = screenName.screen_internal_name;
      const elemIndex = arr.findIndex((elem) => elem.appNameId === screenName.app_en_display_name);
      if (elemIndex < 0) {
        arr.push({
          appNameId: screenName.app_en_display_name,
          appName: screenName[`app_${language}_display_name`],
          subMenu: [
            { screenId, screenNameId, screenName: screenName[`screen_${language}_display_name`] },
          ],
        });
      } else {
        arr[elemIndex].subMenu.push({
          screenId,
          screenNameId,
          screenName: screenName[`screen_${language}_display_name`],
        });
      }
      return arr;
    }, []);
    menuGroup = menu;
  }
  return (
    <SideNav
      theme='light'
      open={showSideNav}
      onClose={() => setShowSideNav(false)}
      aria-label='Main Navigation'
      className={styles.sideNavigationPadding}
    >
      <SideNavSection>
        {menuGroup.map((obj) => {
          return (
            <SideNavCategory
              key={obj.appNameId}
              label={obj.appName}
              open={category === obj.appNameId}
              onSelected={() => setCategory(obj.appNameId)}
            >
              {obj.subMenu.map((subMenu) => {
                return (
                  <SideNavItem
                    key={subMenu.screenId}
                    selected={userDetails.screen_grid_id === subMenu.screenId}
                    onSelected={() => {
                      navigateTo(obj.appName, subMenu.screenNameId);
                    }}
                    data-testid='sidenav'
                  >
                    {subMenu.screenName}
                  </SideNavItem>
                );
              })}
            </SideNavCategory>
          );
        })}
      </SideNavSection>
    </SideNav>
  );
}
export default SideNavigation;
