import * as React from 'react';
import { useEffect, useState } from 'react';
import { Footer } from '@airbus/components-react';
import { useLanguageContextAPI } from '../../context/LanguageContext';

function Footers() {
  const { t } = useLanguageContextAPI();
  const [version, setVersion] = useState<{ 'app-version': string; 'commit-sha': string } | null>(
    null,
  );
  useEffect(() => {
    async function fetchVersion() {
      const response = await fetch('/version.json');
      if (response.ok) {
        const payload = await response.json();
        setVersion(payload);
      } else {
        setVersion(null);
      }
    }
    fetchVersion();
  }, []);

  return (
    <Footer
      primaryLinks={[
        { label: t('welcome'), href: '#' },
        {
          label: t('privacy-policy'),
          href: '/MBD CP V2 Data Privacy Statement.pdf',
          target: '_blank',
        },
      ]}
      copyright={` Airbus ${new Date().getFullYear().toString()} | ${version ? version['app-version'] : ''}`}
    ></Footer>
  );
}

export default Footers;
