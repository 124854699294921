import React from 'react';

import { useLanguageContextAPI } from '../../context/LanguageContext';

import DeSVG from '../../assets/locale/de.svg';
import EnSVG from '../../assets/locale/en.svg';
import Image from 'next/image';
import { Button } from '@airbus/components-react';

const languageMap = {
  de: <Image src={DeSVG} alt='DE' />,
  en: <Image src={EnSVG} alt='EN' />,
};

export default function LanguageSwitcher() {
  const { language, changeLang } = useLanguageContextAPI();

  function toggleLanguageKeys() {
    changeLang(language === 'en' ? 'de' : 'en'); // getting changed by event listener cb at useTranslation();
  }
  return (
    <Button variant='ghost' onClick={toggleLanguageKeys}>
      {language.toUpperCase()} &nbsp;
      {languageMap[language]}
    </Button>
  );
}
