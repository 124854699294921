import React, { Dispatch, SetStateAction } from 'react';

import { Inline, Tab, Tabs } from '@airbus/components-react';

interface ScreenTabsProps {
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ScreenTabs = (props: ScreenTabsProps) => {
  const { selectedTab, setSelectedTab } = props;
  return (
    <Inline spacing='2-x' align='space-between'>
      <Tabs
        aria-label='Tabs purpose label'
        value={selectedTab}
        onChange={(event, value) => {
          setSelectedTab(value);
        }}
      >
        <Tab {...a11yProps(0)}>NPI Tool</Tab>
      </Tabs>
    </Inline>
  );
};

export default ScreenTabs;
